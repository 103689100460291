import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../PreviewCompatibleImage'

const ImageTextRow = ({ title, text, imageFirst, backgroundColor, ...item }) => {
  const components = [
    <div key="text" className="column is-half">
      <h2 className="is-size-2 has-text-weight-medium description">{title}</h2>
      <p className="is-size-6 description block-description">{text}</p>
    </div>,
    <div key="image" className="column is-half is-hidden-mobile">
      <PreviewCompatibleImage imageInfo={item} />
    </div>,
  ]

  if (imageFirst) {
    components.reverse()
  }

  components.push(
    <div key="image-mobile" className="column is-half is-hidden-tablet">
      <PreviewCompatibleImage imageInfo={item} />
    </div>
  )

  return (
    <div className="columns is-variable is-6" style={{ backgroundColor, margin: 0 }}>
      {components}
    </div>
  )
}

// (
//   <div className="columns">
//     <div key={item.text} className="column is-one-half">
//
//     </div>
//     {gridItems.map(item => (
//       <div key={item.text} className="column is-6">
//         <section className="section">
//           <div className="has-text-centered">
//             <div
//               style={{
//                 width: '240px',
//                 display: 'inline-block',
//               }}
//             >
//               <PreviewCompatibleImage imageInfo={item} />
//             </div>
//           </div>
//           <p>{item.text}</p>
//         </section>
//       </div>
//     ))}
//   </div>
// )

ImageTextRow.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default ImageTextRow
