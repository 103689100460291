import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ImageTextRow from '../components/landing/ImageTextRow'

export const AboutPageTemplate = ({ title, subtitle, description, infoSections, bottomCTA }) => (
  <div className="content page-content">
    <section className="hero is-medium is-family-primary has-text-white pulse-hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="primary-title">
            <h2 className="title is-size-1 has-text-weight-medium has-text-white">{title}</h2>
            <div className="decorator-line" />
          </div>
          <p className="subtitle is-size-5 has-text-weight-medium has-text-white">{subtitle}</p>
        </div>
      </div>
    </section>
    <section className="section block-section">
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <p className="description has-text-centered is-size-5 has-text-weight-medium">
            {description}
          </p>
        </div>
      </div>
    </section>
    {infoSections.map((section, i) => (
      <section
        key={section.title}
        className={`section block-section ${i % 2 === 0 ? 'striped' : ''}`}
      >
        <ImageTextRow {...section} imageFirst={i % 2 !== 0} />
      </section>
    ))}
    <section className={`section block-section ${infoSections.length % 2 === 0 ? 'striped' : ''}`}>
      <div className="container has-text-centered">
        <p className="cta-text is-size-3 has-text-weight-medium is-marginless">{bottomCTA.text}</p>
        <Link to={bottomCTA.link} className="cta-button has-text-white">
          <span>{bottomCTA.buttonText}</span>
        </Link>
      </div>
    </section>
  </div>
)

AboutPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        description={frontmatter.description}
        bottomCTA={frontmatter.bottomCTA}
        infoSections={frontmatter.infoSections}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        description
        infoSections {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          text
        }
        bottomCTA {
          text
          link
          buttonText
        }
      }
    }
  }
`
